import React, { useContext, useEffect } from "react";
import { withPrefix } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Flex,
  Link as Anchor,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import Layout2 from "../components/layout-2";
import { TLink } from "../components/tlink";
import SEO from "../components/seo";

const ProductsPage = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Kaminds Nutrichem Private Limited",
    alternateName: "kaminds",
    url: " https://www.kaminds.com/products/ ",
    logo:
      "https://www.kaminds.com/static/d2284c154057c362c68fe045665929d5/63311/Trademark%20Kaminds_RGB%20Logo_Logo_Trademark.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+917568015258",
      contactType: "technical support",
      areaServed: "IN",
      availableLanguage: ["en", "Hindi"],
    },
    sameAs: [
      "https://www.linkedin.com/company/kaminds",
      " https://www.kaminds.com/products/ ",
    ],
  };

  return (
    <Layout2>
      <SEO
        title="Products"
        description="Ingredients to provide the right solutions for your products."
        keywords="product list, Kaminds"
        links={[
          { rel: "canonical", href: "https://www.kaminds.com/products/" },
        ]}
        schemaMarkup={schemaMarkup}
      />
      <Box marginTop="60px" position="relative" overflow="hidden">
        <Box>
          <Box
            _before={{
              content: '""',
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              backgroundImage: "linear-gradient(to bottom right,#000,#000)",
              opacity: "0.4",
            }}
            style={{
              backgroundImage: `url("/products.png")`,
              backgroundSize: "2550px",
              width: "9000px",
              height: "500px",
              backgroundRepeat: "repeat-x",
              animation: "products 300s linear infinite",
            }}
          ></Box>
          {/* <StaticImage
            src="../images/products.png"
            placeholder="tracedSVG"
            tracedSVGOptions={{ color: "#282765" }}
            width={1918}
            height={500}
            alt="human-nutrition"
            style={{
              display: "block",
              opacity: "0.5",
              animation: "products 300s linear infinite",
            }}
          /> */}
        </Box>
        <Box
          margin="auto"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          {/* <Box maxWidth="1300px" width="100%" margin="0 auto"> */}
          <Flex margin="0 auto" flexDirection="column" textAlign="center">
            <Box
              as="h1"
              fontSize={["80px", "100px", "110px", "120px", "125px"]}
              fontWeight="500"
              color="#fff"
              width="100%"
              fontFamily="Fjalla One !important"
            >
              PRODUCTS
            </Box>
            <Box
              as="p"
              color="#fff"
              fontSize={["20px", "22px", "22px", "22px", "24px"]}
              marginTop="10px"
              //   fontWeight="500"
            >
              Ingredients to provide the right solutions for your brands
            </Box>
          </Flex>
          {/* </Box> */}
        </Box>
      </Box>
      <Box marginTop="50px" padding="1em">
        <Flex
          direction="column"
          maxWidth="1400px"
          width="100%"
          margin="0 auto"
          // background="#fce7dd"
          padding="2em"
        >
          <Box as="h2" fontSize="25px">
            Products
          </Box>
          <Flex marginTop="20px" wrap="wrap" alignItems="start">
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Amino Acids
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>
                    Arginine
                    {/* <Accordion allowToggle>
                      <AccordionItem borderTopWidth="0">
                        <h2>
                          <AccordionButton>
                            <Box flex="1" textAlign="left" color="#d21217">
                              Product A
                            </Box>
                            <AccordionIcon color="#d21217" />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} color="#282765">
                          <Box
                            as="h2"
                            fontSize="20px"
                            color="#282765"
                            marginTop="5px"
                          >
                            Industries
                          </Box>
                          <UnorderedList marginTop="10px">
                            <ListItem
                              _hover={{ textDecoration: "underline" }}
                              marginBottom="0"
                            >
                              <TLink to="/industries/human-nutrition">
                                Human Nutrition
                              </TLink>
                            </ListItem>
                            <ListItem
                              _hover={{ textDecoration: "underline" }}
                              marginBottom="0"
                            >
                              <TLink to="/industries/personal-care">
                                Personal Care
                              </TLink>
                            </ListItem>
                          </UnorderedList>
                        </AccordionPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex="1" textAlign="left" color="#d21217">
                              Product B
                            </Box>
                            <AccordionIcon color="#d21217" />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} color="#282765">
                          <Box
                            as="h2"
                            fontSize="20px"
                            color="#282765"
                            marginTop="5px"
                          >
                            Industries
                          </Box>
                          <UnorderedList marginTop="10px">
                            <ListItem
                              _hover={{ textDecoration: "underline" }}
                              marginBottom="0"
                            >
                              <TLink to="/industries/personal-care">
                                Personal Care
                              </TLink>
                            </ListItem>
                          </UnorderedList>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion> */}
                  </td>
                </tr>
                <tr>
                  <td>BCAA</td>
                </tr>
                <tr>
                  <td>Citrulline DL-Malate</td>
                </tr>
                <tr>
                  <td>Glutamine</td>
                </tr>
                <tr>
                  <td>Glycine</td>
                </tr>
                <tr>
                  <td>Isoleucine</td>
                </tr>
                <tr>
                  <td>L-Citrulline</td>
                </tr>
                <tr>
                  <td>Leucine</td>
                </tr>
                <tr>
                  <td>Lysine</td>
                </tr>
                <tr>
                  <td>Methionine</td>
                </tr>
                <tr>
                  <td>Taurine</td>
                </tr>
                <tr>
                  <td>Theanine</td>
                </tr>
                <tr>
                  <td>Tyrosine</td>
                </tr>
                <tr>
                  <td>Valine</td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#252869",
                    }}
                  >
                    Vitamins &amp; Minerals
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <span style={{ color: "#d21217" }}>
                          Straight Vitamins &amp; Minerals
                        </span>
                        <ul style={{ marginLeft: "50px" }}>
                          <li>Vitamin A Acetate 325 CWS</li>
                          <li> Vitamin A Palmitate 250 CWS</li>
                          <li>Vitamin A Palmitate 500 CWS</li>
                          <li>Vitamin B3 (Niacinamide)</li>
                          <li>Vitamin C (Ascorbic Acid)</li>
                          <li>Vitamin D2 100 CWS </li>
                          <li>Vitamin D2 500 CWS</li>
                          <li>Vitamin D3 100 CWS</li>
                          <li> Vitamin D3 500 CWS</li>
                          <li>Vitamin E 50%</li>
                        </ul>
                      </li>
                    </ul>
                    {/* <Accordion allowToggle>
                      <AccordionItem borderTopWidth="0" border="none">
                        <h2>
                          <AccordionButton padding="2px">
                            <Box flex="1" textAlign="left" color="#d21217">
                              Straight Vitamins &amp; Minerals
                            </Box>
                            <AccordionIcon color="#d21217" />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} color="#d21217">
                          <ul style={{ marginLeft: "50px" }}>
                            <li>Vitamin A Acetate 325 CWS</li>
                            <li> Vitamin A Palmitate 250 CWS</li>
                            <li>Vitamin A Palmitate 500 CWS</li>
                            <li>Vitamin B3 (Niacinamide)</li>
                            <li>Vitamin C (Ascorbic Acid)</li>
                            <li>Vitamin D2 100 CWS </li>
                            <li>Vitamin D2 500 CWS</li>
                            <li>Vitamin D3 100 CWS</li>
                            <li> Vitamin D3 500 CWS</li>
                            <li>Vitamin E 50%</li>
                          </ul>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion> */}
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <a href="https://kaminds.com/vitaserve">
                          Customised Blends
                        </a>
                        <ul style={{ marginLeft: "50px" }}>
                          <li>Vitamin Premixes</li>
                          <li>Mineral Premixes</li>
                          <li>Vitamin Mineral Premixes</li>
                          <li>Amino Acid Premixes</li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr> */}
              </tbody>
            </Box>
            {/* <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Plant Essential Oils
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Turmeric Oil</td>
                </tr>
                <tr>
                  <td>Ginger Oil</td>
                </tr>
                <tr>
                  <td>Peppermint Oil</td>
                </tr>
                <tr>
                  <td>Eucalyptus Oil</td>
                </tr>
                <tr>
                  <td>Ajowan Oil</td>
                </tr>
                <tr>
                  <td>Cinnamon Oil</td>
                </tr>
                <tr>
                  <td>Citronella Oil</td>
                </tr>
                <tr>
                  <td>Cypress Oil</td>
                </tr>
                <tr>
                  <td>Lemongrass Oil</td>
                </tr>
                <tr>
                  <td>Patchouli Oil</td>
                </tr>
                <tr>
                  <td>Tea Tree Oil</td>
                </tr>
                <tr>
                  <td>Thyme Oil</td>
                </tr>
                <tr>
                  <td>Vetiver Oil</td>
                </tr>
                <tr>
                  <td>Davana Oil</td>
                </tr>
                <tr>
                  <td>Geranium Oil</td>
                </tr>
                <tr>
                  <td>Palmarosa Oil</td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Colours
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Caramel</td>
                </tr>
                <tr>
                  <td>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        Natural Colours
                        <ul style={{ marginLeft: "50px" }}>
                          <li>Paprika Colours</li>
                          <li>Annatto Colours</li>
                          <li>Anthocyanin Colours</li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Floral Extracts
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Mimosa Fragrance – Absolute &amp; Concrete</td>
                </tr>
                <tr>
                  <td>Tuberose Fragrance – Absolute &amp; Concrete</td>
                </tr>
                <tr>
                  <td>Jasmine Fragrance – Absolute &amp; Concrete</td>
                </tr>
                <tr>
                  <td>Jasmine Sambac Fragrance – Absolute &amp; Concrete</td>
                </tr>
                <tr>
                  <td>Standardised Extracts – Absolute &amp; Concrete</td>
                </tr>
              </tbody>
            </Box> */}
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Starches
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Native Starches</td>
                </tr>
                <tr>
                  <td>Modified Starches</td>
                </tr>
                <tr>
                  <td>Vital Wheat Gluten</td>
                </tr>
                <tr>
                  <td>Native Starches (Industrial)</td>
                </tr>
                <tr>
                  <td>Modified Wheat Starches (Industries)</td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#252869",
                    }}
                  >
                    Colours
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <span style={{ color: "#d21217" }}>Caramel Colour</span>
                        <ul style={{ marginLeft: "50px" }}>
                          <li>Class I</li>
                          <li>Class II</li>
                          <li>Class III</li>
                          <li>Class IV</li>
                          <li>Class IV/Low 4-MeI</li>
                          <li>Organic/Non-GMO</li>
                          <li>Caramelized Sugar Syrups</li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#252869",
                    }}
                  >
                    Proteins
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <span style={{ color: "#d21217" }}>Animal Protein</span>
                        <ul style={{ marginLeft: "50px" }}>
                          <li>Whey Protein Concentrate</li>
                          <li>Whey Protein Isolate</li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        Plant Protein
                        <ul style={{ marginLeft: "50px" }}>
                          <li>Soy Protein Concentrate</li>
                          <li>Soy Protein Isolate</li>
                          <li>Pea Protein</li>
                          <li>Chickpea Protein</li>
                          <li>Mung Bean Protein</li>
                          <li>Lentil Protein</li>
                        </ul>
                      </li>
                    </ul>
                  </td>
                </tr> */}
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Grain Products
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Oats</td>
                </tr>
                <tr>
                  <td>Oat Beta Glucan</td>
                </tr>
                <tr>
                  <td>Oat Protein</td>
                </tr>
                <tr>
                  <td>Oat Oil</td>
                </tr>
              </tbody>
            </Box>
            {/* <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Dried Whole Crop
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Dried Chilli Whole</td>
                </tr>
                <tr>
                  <td>Dried Turmeric Whole</td>
                </tr>
                <tr>
                  <td>Dried Ginger Whole</td>
                </tr>
                <tr>
                  <td>Dried Black Pepper</td>
                </tr>
              </tbody>
            </Box> */}
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Sweeteners
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Invert Syrup</td>
                </tr>
                <tr>
                  <td>Sucralose</td>
                </tr>
                <tr>
                  <td>Acesulfame K</td>
                </tr>
                <tr>
                  <td>Aspartame</td>
                </tr>
                <tr>
                  <td>Xylitol</td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Cocoa Powders &amp; Chocolate Ingredients
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Alkalized Cocoa Powder</td>
                </tr>
                <tr>
                  <td>Black Cocoa Powder</td>
                </tr>
                <tr>
                  <td>Natural Cocoa Powder</td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Carotenoids
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Astaxanthin</td>
                </tr>
                <tr>
                  <td>Lutein</td>
                </tr>
                <tr>
                  <td>Zeaxanthin</td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Canned &amp; Ready Food Products
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Tomato Paste</td>
                </tr>
                <tr>
                  <td>Mango Pulp</td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Oils &amp; Oil Powders
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Oat Oil</td>
                </tr>
                <tr>
                  <td>Omega 3 Oils</td>
                </tr>
              </tbody>
            </Box>
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Speciality Fats
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Cocoa Butter Substitutes</td>
                </tr>
                <tr>
                  <td>Dairy Fat Alternatives</td>
                </tr>
              </tbody>
            </Box>
            {/* <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Oleoresins
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Capsicum Oleoresin</td>
                </tr>
                <tr>
                  <td>Turmeric Oleoresin</td>
                </tr>
                <tr>
                  <td>Curcumin removed Turmeric Oleoresin (CRTO)</td>
                </tr>
                <tr>
                  <td>Ginger Oleoresin</td>
                </tr>
                <tr>
                  <td>Paprika Oleoresin</td>
                </tr>
              </tbody>
            </Box> */}
            {/* <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Standardized Extracts
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Curcumin Powder with Minimum 95% Curcuminoids</td>
                </tr>
                <tr>
                  <td>Capsaicin</td>
                </tr>
              </tbody>
            </Box> */}
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Fibres
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Pectin</td>
                </tr>
              </tbody>
            </Box>
            {/* <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#2e256a",
                    }}
                  >
                    Spice Powders
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Chilli Powder</td>
                </tr>
                <tr>
                  <td>Turmeric Powder</td>
                </tr>
              </tbody>
            </Box> */}
            <Box
              as="table"
              width={[
                "100%",
                "calc(100% / 2 - 40px)",
                "calc(100% / 2 - 40px)",
                "calc(100% / 3 - 40px)",
              ]}
              style={{
                margin: "10px 20px",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "#d21217 solid 1px",
                      paddingLeft: "10px",
                      fontSize: "20px",
                      color: "#252869",
                    }}
                  >
                    <a href="https://kaminds.com/vitaserve">
                      Customised Blends
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody style={{ width: "100%", color: "#d21217" }}>
                <tr>
                  <td>Vitamin Premixes</td>
                </tr>
                <tr>
                  <td>Mineral Premixes</td>
                </tr>
                <tr>
                  <td>Vitamin Mineral Premixes</td>
                </tr>
                <tr>
                  <td>Amino Acid Premixes</td>
                </tr>
              </tbody>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Layout2>
  );
};

export default ProductsPage;
